import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Grid, Typography } from '@mui/material';
import { styles } from '../styles';
import logoSplash from '../../images/logoSplash.svg';
import ASLogoWide from '../../images/ASLogoWide.png';
class PrintHeader extends React.Component {
  state = {
    curTime: new Date().toLocaleString(),
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={!this.props.cert ? classes.splashPrint : classes.printSplash}>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item style={{ width: 200 }}>
              <Grid container>
                {/*<Grid item>
                  {this.props.activeModule === 'Optimize' ? (
                    <SvgIcon fontSize='small' style={{ marginTop: 6, marginRight: 5 }}>
                      <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' x='0' y='0' version='1.1' viewBox='0 0 36 36'>
                        <defs />
                        <path id='Rectangle_2364' d='M9.7 15.5h5.6v5.6H9.7z' transform='rotate(-45.001 12.54 18.302)' />
                        <path id='Rectangle_2365' d='M15.3 9.9h5.6v5.6h-5.6z' transform='rotate(-45.001 18.063 12.68)' />
                        <path id='Rectangle_2363' d='M20.8 15.5h5.6v5.6h-5.6z' transform='rotate(-45.001 23.606 18.286)' />
                        <path className={classes.tabIconStroke} fill='none' stroke='#464749' strokeMiterlimit='10' strokeWidth='3.499965' d='M2.563 18.108L17.978 2.694l15.415 15.414-15.415 15.415z' />
                      </svg>
                    </SvgIcon>
                  ) : this.props.activeModule === 'Prioritize' ? (
                    <SvgIcon fontSize='small' style={{ marginTop: 6, marginRight: 5 }}>
                      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                        <path fill='none' stroke='#464749' strokeWidth='3.499965' strokeMiterlimit='10' d='M2.585 17.983L18 2.568l15.415 15.415L18 33.397z' />
                        <path fill='#1879C0' d='M9.427 18.06l8.556-8.556 8.556 8.556-8.556 8.556z' />
                      </svg>
                    </SvgIcon>
                  ) : null}
                  </Grid>*/}
                <Grid item>
                  <Typography variant='h6' style={{ marginTop: 4 }}>
                    {this.props.activeModule}
                  </Typography>
                </Grid>
              </Grid>
              <Typography style={{ fontSize: 13 }}>{this.state.curTime}</Typography>
            </Grid>
            <Grid item>
              <Grid container>
                {this.props.primaryLogo === 'SetpointLogo' ? (
                  <Grid item>
                    <img src={`/images/SetpointLogo.png`} alt='Setpoint Logo' className={classes.splashLogoPrintSetpoint} />
                  </Grid>
                ) : this.props.primaryLogo === 'BakerHughesLogo' ? (
                  <Grid item>
                    <img src={`/images/BakerHughesLogo.png`} alt='Baker Hughes Logo' className={classes.splashLogoPrintBakerHughes} />
                  </Grid>
                ) : this.props.primaryLogo === 'VectorLogo' ? (
                  <Grid item>
                    <img src={ASLogoWide} alt='Vector Logo' className={classes.splashLogoPrintVectorAS} />
                  </Grid>
                ) : this.props.primaryLogo === 'GEBoothLogo' ? (
                  <Grid item>
                    <img src={this.props.colorMode === 'dark' ? `/images/GEBoothLogoInverted.png` : `/images/GEBoothLogo.png`} alt='GE Booth Logo' className={classes.splashLogoPrintGEBooth} />
                  </Grid>
                ) : null}
                {/*this.props.primaryLogo !== '' || this.props.secondaryLogo !== '' ? (
                  <div
                    style={{
                      marginLeft: 45,
                      paddingLeft: 35,
                      marginTop: 10,
                      borderLeft: '1px solid #ddd',
                      height: 20,
                    }}
                  ></div>
                  ) : null*/}
              </Grid>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <Grid container>
                <Grid item>
                  <Typography variant='body2' style={{ fontSize: 10, marginTop: 10, marginRight: 18 }}>
                    Powered By
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={logoSplash} alt='My logo' className={classes.splashPrintLogo} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    primaryLogo: state.auth.primaryLogo,
    secondaryLogo: state.auth.secondaryLogo,
    activeModule: state.currentModule.currentModule,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(PrintHeader));
