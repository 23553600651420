import { alpha } from '@mui/material/styles';

export const styles = (theme) => ({
  abbreviateMax: {
    '@media only screen and (max-width: 705px)': {
      display: 'none',
    },
  },
  abbreviateMin: {
    '@media only screen and (min-width: 705px)': {
      display: 'none',
    },
  },
  accordion: {
    marginBottom: 5,
    border: 'none',
  },
  accordionSummary: {
    borderRadius: 8,
    backgroundColor: theme.palette.tile.primary,
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
  },
  accordionDialogDivider: {
    margin: '10px -16px',
  },
  accordionDetails: {
    borderBottomWidth: 0,
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
    padding: '18px 16px 8px 16px',
    backgroundColor: theme.palette.tile.color,
    '&:hover': {
      backgroundColor: theme.palette.tile.color,
    },
  },
  accordionSubtitle: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionTitle: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  addVariable: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alert: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
    '@media print': {
      padding: theme.spacing(1),
      paddingBottom: 12,
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
  },
  alertIcon: {
    marginBottom: -5,
    marginRight: 4,
  },
  appBar: {
    marginLeft: 205,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${205}px)`,
    },
    '@media print': {
      display: 'none',
    },
    backgroundColor: theme.palette.appBar.color,
  },
  autoCompleteListItem: {
    color: theme.palette.gray.dark,
    textDecoration: 'none',
    padding: '3px 10px',
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.list.hover,
      color: theme.palette.secondary.main,
    },
  },
  autoCompletePopper: {
    zIndex: 1300,
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10,
    backgroundColor: theme.palette.primary.main,
  },
  avoidBreak: {
    '@media print': {
      breakInside: 'avoid',
    },
  },
  back: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: '-5px',
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  blueBG: {
    backgroundColor: theme.palette.blue.color,
  },
  blueBGLight: {
    backgroundColor: theme.palette.blue.light,
  },
  blueText: {
    color: theme.palette.blue.color,
  },
  blueLightText: {
    color: theme.palette.blue.light,
  },
  body: {
    lineHeight: 2,
  },
  bodyCheckbox: {
    marginTop: 8,
    marginRight: '12px !important',
    float: 'left',
  },
  bodyHeader: {
    marginBottom: 4,
    letterSpacing: '.5px',
  },
  bodyHeaderSelect: {
    marginBottom: 4,
    letterSpacing: '.5px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  bodyHeaderSelectInactive: {
    marginBottom: 4,
    letterSpacing: '.5px',
    cursor: 'pointer',
    color: theme.palette.gray.light,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  bodyList: {
    lineHeight: 2,
    marginBottom: 20,
  },
  bold: {
    fontWeight: 500,
  },
  buttonIcon: {
    marginRight: 6,
    marginLeft: -3,
    marginTop: -3,
    //color: theme.palette.primary.main,
  },
  buttonFilter: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  buttonFilterClear: {
    marginTop: theme.spacing(2),
  },
  buttonFilterInline: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonFilterRight: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  buttonFilterLeft: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  buttonToggleTrue: {
    backgroundColor: theme.palette.blue.transparent,
  },
  buttonWrapper: {
    margin: '6px 0px 2px 0px',
    display: 'block',
  },
  cell: {
    padding: '10px 0px 8px 0px',
  },
  centerDialog: {
    margin: '2% auto 4% auto',
    [theme.breakpoints.down('lg')]: {
      margin: 8,
    },
  },
  chart: {
    '@media print': {
      marginTop: -10,
      marginBottom: -10,
    },
    width: '98%',
  },
  chartButton: {
    marginTop: -8,
    marginRight: -8,
    '@media print': {
      display: 'none',
    },
  },
  chartButtonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  chartProgress: {
    marginLeft: theme.spacing(1),
  },
  checklistCell: {
    borderBottom: '1px solid rgba(224, 224, 224, .2)',
  },
  checkbox: {
    WebkitPrintColorAdjust: 'exact !important',
    colorAdjust: 'exact !important',
    borderRadius: 2,
    minWidth: 14,
    width: 14,
    height: 14,
    marginRight: 4,
    '@media print': {
      minWidth: 12,
      width: 12,
      height: 12,
    },
  },
  checkboxLegend: {
    WebkitPrintColorAdjust: 'exact !important',
    colorAdjust: 'exact !important',
    borderRadius: 2,
    minWidth: 12,
    width: 12,
    height: 12,
    marginRight: 6,
    marginTop: -2,
  },
  checkboxIconResize: {
    width: 18,
    '@media print': { width: 14, marginLeft: -2 },
  },
  checkboxGray: {
    background: '#ccc',
  },
  checkboxGrayDark: {
    background: '#888',
  },
  checkboxBlack: {
    background: '#333',
  },
  checkboxGreen: {
    background: '#308D3B',
  },
  checkboxYellow: {
    background: '#e3a61b',
  },
  checkboxPurple: {
    background: '#795BA6',
  },
  checkboxRed: {
    background: '#D3001B',
  },
  checklistHeader: {
    marginBottom: 8,
    fontSize: 16,
    '@media print': {
      marginBottom: 4,
      fontSize: 15,
    },
  },
  checklistInfo: {
    marginLeft: -6,
    '@media print': {
      display: 'none',
    },
  },
  checklistInfoIcon: {
    height: 16,
    width: 16,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  checklistInfoIconAppBar: {
    height: 16,
    width: 16,
  },
  checklistItem: {
    height: 20,
    padding: 0,
  },
  checklistItemSelectable: {
    padding: 0,
    '&:hover': { color: theme.palette.secondary.main },
  },
  checklistItemActive: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  checklistText: {
    width: '100%',
    marginLeft: 4,
  },
  clearBG: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  closeButton: {
    margin: 10,
  },
  collapseBody: {
    margin: '16px',
  },
  collapseCell: {
    padding: '0px',
    borderBottom: '0px',
  },
  compare: {
    fontStyle: 'italic',
    fontSize: 12,
    marginTop: 2,
    '@media print': { marginTop: 1 },
  },
  concernExtraMargin: {
    marginTop: 5,
  },
  concernLegend: {
    marginBottom: 5,
    marginTop: 20,
    '@media print': { marginTop: 4 },
  },
  configListItem: {
    width: 180,
  },
  configListItemCheckbox: {
    padding: 2,
  },
  configListItemLabel: {
    fontSize: 12,
  },
  containerSpacingFix: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% + 4px)',
    },
    '@media print': { marginTop: 4 },
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
    },
    marginTop: 52,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    width: '100%',
    '@media print': {
      padding: 4,
      marginTop: 0,
      paddingTop: 0,
    },
  },
  customSelectFilterInline: {
    padding: '0px 9px 0px 9px !important',
  },
  details: {
    marginTop: '64px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '72px',
    },
    '@media print': {
      marginTop: 0,
    },
  },
  detailsMin: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '65px',
    },
    marginTop: '72px',
    '@media print': {
      marginTop: 0,
    },
  },
  deviceInfo: {
    '@media print': {
      fontSize: 12,
    },
    marginBottom: 2,
  },
  diagnosticIcon: {
    marginRight: 10,
    marginTop: 4,
    marginLeft: -2,
    marginBottom: -2,
  },
  diagnosticIconSmall: {
    marginRight: 5,
    marginTop: 0,
    marginLeft: -2,
  },
  diagnosticsDivider: {
    margin: '5px 0px 10px 0px',
    '@media print': {
      margin: '2px 0px 4px 0px',
    },
  },
  diagnosticsPaper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    '@media print': {
      boxShadow: 'none',
      border: 'none',
      padding: '0px !important',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
    },
  },
  diagnosticSetTitle: {
    marginTop: 8,
    '@media print': {
      marginTop: 4,
      fontSize: 13,
      height: 18,
    },
  },
  dialog: {
    [theme.breakpoints.down('lg')]: {
      margin: 8,
    },
  },
  dialogDivider: {
    borderColor: theme.palette.border.color,
  },
  dialogPadding: {
    padding: '0px 12px',
  },
  dialogTitle: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
    //cursor: 'move',
  },
  dialogToggleButtons: {
    padding: '18px',
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
  },
  displayNone: {
    display: 'none',
  },
  divider: {
    margin: '8px 0px 4px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '6px 0px 2px 0px',
    },
    '@media print': {
      margin: '4px 0px 2px 0px',
    },
    width: '100%',
  },
  dividerOptimize: {
    margin: '8px 0px 4px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '6px 0px 2px 0px',
    },
    '@media print': {
      margin: '4px 0px 2px 0px',
      display: 'none',
    },
    width: '100%',
  },
  dividerMd: {
    margin: '4px 0px 8px 0px',
    '@media print': {
      margin: '4px 0px 8px 0px',
    },
    width: '100%',
  },
  dividerSm: {
    margin: '4px 0px 4px 0px',
  },
  dividerXl: {
    margin: '25px 0px 25px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '12px 0px 12px 0px',
    },
    width: '100%',
  },
  dividerVertical: {
    height: '100%',
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
    marginRight: 4,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: 205,
      flexShrink: 0,
      '@media print': {
        width: 0,
      },
    },
  },
  drawerPaper: {
    width: 205,
    textAlign: 'center',
  },
  dropzone: {
    height: 140,
    width: 140,
    border: '2px dashed rgb(170, 186, 197)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 16,
    margin: '10px 0px 20px 0px',
  },
  embedPaper: {
    border: '1px solid #ddd',
    borderColor: theme.palette.border.color,
  },
  error: {
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 20,
  },
  eventIcon: {
    margin: '0px 8px 0px 2px',
    position: 'relative',
    minWidth: '15px',
  },
  eventItem: {
    paddingLeft: '5px',
    fontSize: 13,
    width: '50%',
    minHeight: 42,
  },
  expansionTab: {
    cursor: 'pointer',
    margin: '32px -5px 16px -5px',
    padding: '0px 5px',
    //borderBottom: '1px solid #ddd',
    '&:hover': {
      //backgroundColor: theme.palette.border.color,
      color: theme.palette.secondary.main,
    },
  },
  expansionTabTop: {
    cursor: 'pointer',
    margin: '8px -5px 16px -5px',
    padding: '0px 5px',
    //borderBottom: '1px solid #ddd',
    '&:hover': {
      //backgroundColor: theme.palette.border.color,
      color: theme.palette.secondary.main,
    },
  },
  expansionTabBorder: {
    width: '100%',
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
  },
  fakeButton: {
    fontSize: 14,
    borderRadius: 5,
    height: 38,
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    padding: '8px 15px 8px 15px',
    cursor: 'pointer',
    fontWeight: '600',
    color: theme.palette.primary.main,
    marginTop: 3,
    '&:hover': {
      backgroundColor: 'rgba(24, 69, 104, 0.05)',
      borderColor: theme.palette.primary.main,
    },
  },
  field: {
    borderColor: theme.palette.border.color,
    border: '1px solid',
    borderBottomWidth: 0,
  },
  fieldName: {
    padding: '8px 12px 6px 12px',
    backgroundColor: theme.palette.gray.ultraLight,
  },
  fieldValue: {
    padding: '8px 12px 6px 12px',
  },
  fileInput: {
    display: 'none',
  },
  filter: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    width: 160,
    [theme.breakpoints.down('md')]: {
      width: 140,
      marginRight: theme.spacing(1),
    },
    '@media print': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  form: {
    flexWrap: 'wrap',
  },
  formSelect: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    minWidth: 160,
    [theme.breakpoints.down('md')]: {
      minWidth: 140,
      marginRight: theme.spacing(1),
    },
  },
  hidePaper: {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  inlineFormSelect: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    minWidth: 250,
  },
  formSelectShort: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
    minWidth: 100,
  },
  grayIcon: {
    color: theme.palette.gray.color,
    width: 14,
    marginRight: 4,
  },
  grayText: {
    color: theme.palette.gray.color,
  },
  grayTextLight: {
    color: theme.palette.gray.light,
  },
  greenBG: {
    backgroundColor: theme.palette.green.color,
  },
  greenBGLight: {
    backgroundColor: theme.palette.green.light,
  },
  greenBorder: {
    borderColor: theme.palette.green.color,
  },
  greenText: {
    color: theme.palette.green.color,
  },
  gridHeight: {
    height: 500,
  },
  guide: {
    height: '5px',
  },
  helperScreenshot: {
    borderRadius: 6,
    border: '2px solid #bbb',
    width: '100%',
    marginTop: 8,
  },
  helperSection: {
    marginBottom: 30,
  },
  helperText: {
    fontSize: 11,
  },
  highlight: {
    backgroundColor: '#e9edf0',
  },
  icon: {
    margin: '0px 8px 0px 2px',
    position: 'relative',
    minWidth: '30px',
  },
  iconActive: {
    color: theme.palette.blue.color,
  },
  iconGray: {
    color: theme.palette.gray.dark,
  },
  image: {
    borderRadius: 4,
    border: '1px solid #bbb',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  imageExpanded: {
    margin: 'auto',
    marginBottom: -7,
    marginLeft: -16,
  },
  imagePrint: {
    borderRadius: 4,
    border: '1px solid #bbb',
  },
  inactiveArrow: {
    color: theme.palette.gray.light,
  },
  inactiveRec: {
    color: theme.palette.gray.light,
  },
  inactiveStep: {
    fontSize: 15,
    marginTop: 20,
    cursor: 'pointer',
    color: theme.palette.gray.light,
    '&:hover': { color: theme.palette.secondary.main },
  },
  inactiveStepOpen: {
    fontSize: 15,
    marginTop: 20,
    cursor: 'pointer',
    '&:hover': { color: theme.palette.secondary.main },
  },
  indent1: {
    paddingLeft: 25,
    paddingBottom: 5,
  },
  infographicTile: {
    WebkitPrintColorAdjust: 'exact !important',
    colorAdjust: 'exact !important',
    height: 300,
    padding: 20,
    borderRadius: 0,
    border: 'none',
    color: 'white',
  },
  infographicTile1: {
    backgroundImage: 'linear-gradient(#5F82CF, #385080)',
    //border: '2px solid #7793CB',
  },
  infographicTile2: {
    backgroundImage: 'linear-gradient(#4B98CD, #336981)',
    //border: '2px solid #77C3DB',
  },
  infographicTile3: {
    backgroundImage: 'linear-gradient(#5BBDB8, #2D7777)',
    //border: '2px solid #73CFCD',
  },
  infographicTile4: {
    backgroundImage: 'linear-gradient(#47C184, #277C55)',
    //border: '2px solid #80CAA7',
  },
  infographicTile5: {
    backgroundImage: 'linear-gradient(#4BB965, #32763D)',
    //border: '2px solid #68C47E',
  },
  infoHelper: {
    fontSize: 14,
    [theme.breakpoints.down('xl')]: {
      fontSize: 13,
    },
  },
  inlineInput: {
    height: 37,
  },
  inputLabelFix: {
    padding: '0px 4px',
    backgroundColor: theme.palette.tile.primary,
  },
  inputLabelFixNoPaper: {
    padding: '0px 4px',
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  issueItem: {
    paddingTop: 3,
    width: 280,
    '@media print': {
      paddingTop: 1,
    },
  },
  issueText: {
    paddingTop: 3,
    '@media print': {
      paddingTop: 1,
    },
  },
  issueName: {
    paddingLeft: 3,
    paddingTop: 3,
    '@media print': {
      paddingTop: 1,
    },
  },
  issuePaper: {
    '@media print': {
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
  },
  issueSubmit: {
    marginRight: 4,
    marginBottom: -8,
    marginTop: 8,
  },
  issueSubmitDivider: {
    margin: '16px -8px 0px -8px',
    width: 'calc(100% + 12px)',
    [theme.breakpoints.down('lg')]: {
      margin: '16px 0px 0px 2px',
      width: 'calc(100% + 4px)',
    },
  },
  printIssueSubmitDivider: {
    width: 'calc(100% + 16px)',
    margin: '8px -8px 0px -8px',
  },
  issueTitle: {
    marginTop: 8,
    '@media print': {
      fontSize: 13,
      marginTop: 4,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  label: {
    margin: theme.spacing(2, 0, 0),
  },
  largeLabel: {
    fontSize: '14px',
  },
  largeText: {
    fontSize: '18px',
  },
  leftMargin: {
    marginLeft: theme.spacing(2),
    '@media print': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
  },
  legendIcon: {
    height: 11,
    paddingTop: 1,
    marginLeft: -5,
  },
  legendItem: {
    marginRight: 24,
  },
  legendSymbol: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: 6,
    marginTop: 6,
  },
  li: {
    padding: '0px',
  },
  listTextSelectable: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  listText: {
    marginBottom: 16,
  },
  listPrimary: {
    fontSize: 15,
    lineHeight: 1,
    '@media print': {
      fontSize: 13,
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: 13,
    },
    '@media only screen and (max-width: 1100px)': {
      fontSize: 12,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  listPrimaryFormatted: {
    fontSize: 14,
  },
  loadIcon: {
    margin: '0px 8px 0px 2px',
    position: 'relative',
    width: '30px',
  },
  loadIconSm: {
    margin: '0px 8px 0px 5px',
    position: 'relative',
    top: '2px',
  },
  loginMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  logo: {
    height: 57,
  },
  logoFull: {
    height: 32,
    marginTop: 16,
  },
  logoContainer: {
    backgroundColor: theme.palette.appBar.color,
    minHeight: 64,
  },
  loopText: {
    marginTop: 10,
  },
  loopTextContainer: {
    marginTop: 10,
  },
  markerGuide: {
    borderRight: '3px solid ',
  },
  marker: {
    borderRight: '3px solid' + theme.palette.tile.primary,
  },
  menuButton: {
    marginRight: 5,
    marginLeft: -10,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuCheckbox: {
    padding: '0px 8px 0px 0px',
    marginLeft: -4,
  },
  menuIcon: {
    marginRight: 16,
    color: theme.palette.primary.main,
  },
  menuItem: {
    //color: theme.palette.gray.dark,
    '&.selected, &.Mui-selected': {
      color: theme.palette.blue.color,
    },
  },
  menuTextActive: {
    color: '#aaa',
  },
  moreSpecs: {
    marginTop: 5,
    marginLeft: -8,
  },
  multipleAutocomplete: {
    width: 358,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: 300,
      marginRight: theme.spacing(1),
    },
    '@media print': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  name: {
    fontWeight: 'bold',
  },
  optimizeTile: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    borderColor: theme.palette.tile.border,
    backgroundColor: theme.palette.tile.primary,
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
    marginTop: 6,
    marginRight: 6,
    '@media print': {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: null,
    },
  },
  optimizeTileInfo: {
    '@media print': {
      lineHeight: 1.4,
    },
  },
  orangeBG: {
    backgroundColor: theme.palette.orange.color,
  },
  orangeBGLight: {
    backgroundColor: theme.palette.orange.light,
  },
  orangeBorder: {
    border: '2px solid',
    borderColor: theme.palette.orange.color,
  },
  orangeText: {
    color: '#bf5221',
  },
  outlineHeader: {
    marginBottom: 4,
  },
  outlineList: {
    marginBottom: 4,
  },
  outlineListHeader: {
    marginTop: 16,
    marginBottom: 4,
  },
  outlineListItem: {
    marginBottom: 2,
    marginLeft: 10,
  },
  mapPaper: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    backgroundColor: theme.palette.tile.primary,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 8,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
    '@media print': {
      padding: '6px 8px',
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
  },
  pageBreak: {
    pageBreakBefore: 'always',
  },
  pageBreakAfter: {
    pageBreakAfter: 'always',
  },
  paginationFix: { '@media print': { marginTop: -10, marginBottom: -12 } },
  picker: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    width: '160px',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      marginRight: theme.spacing(1),
    },
  },
  pinkBG: {
    backgroundColor: theme.palette.pink.color,
  },
  pinkText: {
    color: theme.palette.pink.color,
  },
  popoverResize: {
    maxWidth: 'calc(1100px - 32px)',
    maxHeight: 'calc(100% - 32px)',
  },
  primaryBorder: {
    border: '1px solid',
    borderColor: theme.palette.tile.border,
  },
  printActionsPaper: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    padding: '16px 0px 0px 0px!important',
  },
  printButton: {
    '@media only screen and (max-width: 700px)': {
      display: 'none',
    },
    marginRight: 8,
  },
  printDiagnosticsPaper: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    padding: '0px !important',
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  printWidgetGroup: {
    //marginTop: 40,
    '@media print': {
      marginTop: 0,
    },
  },
  printWidgetSpacer: {
    width: '100%',
    display: 'block',
    '@media print': {
      marginTop: 20,
    },
  },
  printDeviceInfo: {
    fontSize: 12,
    marginBottom: 4,
  },
  printFSRTile: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderStyle: 'solid',
    borderColor: theme.palette.tile.border,
    backgroundColor: theme.palette.tile.primary,
    marginTop: 4,
    marginRight: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
    '@media print': {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: null,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  printIssuePaper: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    backgroundColor: 'transparent',
  },
  printIssueSubmit: {
    marginRight: 4,
    marginBottom: -8,
    marginTop: 0,
  },
  printMargin: {
    '@media print': { marginTop: 20 },
  },
  printMarginSm: {
    '@media print': { marginTop: 8 },
  },
  printOptimizeTile: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderStyle: 'solid',
    borderColor: theme.palette.tile.border,
    backgroundColor: theme.palette.tile.primary,
    marginTop: 4,
    marginRight: 4,
    '@media print': {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: null,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  printPaper: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.tile.primary,
  },
  printPrioritizeTile: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  printIBATile: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    //padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  printReliabilityTile: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  printTablePaper: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    backgroundColor: theme.palette.tile.primary,
  },
  printTilePaper: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    '@media print': {
      padding: '8px',
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
    },
  },
  printSplash: {
    marginBottom: 4,
    marginTop: 1,
    display: 'flex',
  },
  printTitle: {
    fontSize: 15,
  },
  printSubtitle: {
    fontSize: 14,
    marginTop: 8,
  },
  printTestSelect: {
    marginTop: 4,
    marginBottom: -2,
  },
  printTextDisplay: {
    marginRight: 4,
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    width: 98,
    marginBottom: 2,
  },
  printTextDisplayBody: {
    fontSize: 13,
    letterSpacing: '-.02em',
  },
  printTextDisplayLabel: {
    fontSize: 12,
  },
  printWidgetSpacing: {
    paddingBottom: 4,
  },
  prioritizeTile: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: 8,
    cursor: 'pointer',
    backgroundColor: theme.palette.tile.primary,
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  prioritizeTileWidget: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: 8,
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  prioritizeConcernMargin: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px !important',
    },
  },
  prioritizeTextMargin: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: '30px !important',
    },
  },
  progress: {
    padding: '10px',
  },
  progressGuide: {
    marginTop: '-5px',
    height: 6,
  },
  progressLabelPrioritize: {
    height: 30,
  },
  progressText: {
    '@media print': { fontSize: 10 },
  },
  progressWrapper: {
    flexGrow: 1,
  },
  purpleText: {
    color: theme.palette.purple.color,
  },
  recommendationTitle: {
    '@media print': {
      marginTop: 2,
    },
  },
  redBG: {
    backgroundColor: theme.palette.red.color,
  },
  redBGLight: {
    backgroundColor: theme.palette.red.light,
  },
  redBorder: {
    borderColor: theme.palette.red.color,
  },
  redText: {
    color: theme.palette.red.color,
  },
  refreshButton: {
    margin: '20px 0px 0px -5px',
  },
  reliabilityTile: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: 8,
    marginRight: 6,
    borderLeftWidth: 12,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    cursor: 'pointer',
    backgroundColor: theme.palette.tile.primary,
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  ibaTile: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: 8,
    borderLeftWidth: 8,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    backgroundColor: theme.palette.tile.primary,
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  ibaTileHeader: {
    padding: theme.spacing(2),
    paddingBottom: 8,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
  },
  ibaTileHeaderExpanded: {
    padding: theme.spacing(2),
    paddingTop: 24,
    paddingBottom: 16,
    borderRadius: 8,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tile.hover,
    },
  },
  reliabilityTileWidget: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: 8,
    borderLeftWidth: 12,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    backgroundColor: theme.palette.tile.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '@media print': {
      boxShadow: 'none',
      backgroundColor: null,
    },
  },
  repairNumber: {
    marginRight: 8,
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  row: {
    cursor: 'pointer',
  },
  rowActive: {
    backgroundColor: 'rgba(24, 121, 192, .1)',
    '&:hover': {
      backgroundColor: 'rgba(24, 121, 192, .2) !important',
    },
  },
  rowOpen: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
  },
  rowTitle: {
    paddingBottom: 12,
    paddingTop: 6,
  },
  screenshot: {
    marginBottom: 24,
    borderRadius: 8,
    border: '2px solid #bbb',
    marginTop: 10,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: '12px',
      marginLeft: '20px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 150,
    },
  },
  searchField: {
    marginTop: 8,
    marginBottom: 8,
  },
  searchInput: {
    padding: '4px 0px 4px 32px !important',
    color: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 28,
      padding: '0px 0px 0px 32px !important',
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1px',
    [theme.breakpoints.down('lg')]: {
      width: '42px',
    },
  },
  select: {
    padding: 20,
  },
  selectList: {
    width: '100%',
    display: 'block',
    padding: '0px 12px',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  shift: {
    paddingTop: 3,
    marginLeft: 5,
  },
  shiftArrow: {
    marginBottom: -6,
    height: 22,
  },
  singleSelect: {
    cursor: 'pointer',
  },
  smHide: {
    '@media only screen and (max-width: 800px)': {
      display: 'none',
    },
  },
  spacingWorkaround: {
    '& div': {
      marginRight: 20,
      marginBottom: 10,
    },
  },
  sparklineIconButton: {
    marginTop: '17px',
  },
  sparklineDisplayWrapper: {
    padding: '0px 0px 0px 40px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  spec: {
    '@media print': {
      letterSpacing: '-.02em',
    },
  },
  splashPrint: {
    marginBottom: 4,
    marginTop: 1,
    display: 'none',
    '@media print': {
      display: 'flex',
    },
  },
  splashPrintLogo: {
    marginTop: 4,
    width: 120,
  },
  splashLogo: {
    marginTop: '20%',
    width: '100%',
    maxWidth: 220,
  },
  splashLogoBakerHughes: {
    marginTop: 40,
    maxWidth: 250,
    width: '100%',
  },
  splashLogoSetpoint: {
    marginTop: 38,
    maxWidth: 200,
    width: '100%',
  },
  splashLogoGEBooth: {
    marginTop: 38,
    maxWidth: 240,
    width: '100%',
  },
  splashLogoVector: {
    marginTop: 40,
    maxWidth: 300,
    width: '100%',
  },
  splashLogoPrintBakerHughes: {
    marginTop: 8,
    maxWidth: 130,
    width: '100%',
  },
  splashLogoPrintGEBooth: {
    marginTop: 2,
    maxWidth: 120,
    width: '100%',
  },
  splashLogoPrintSetpoint: {
    marginTop: 6,
    maxWidth: 140,
    width: '100%',
  },
  splashLogoPrintVector: {
    marginTop: 4,
    maxWidth: 130,
    width: '100%',
  },
  splashLogoPrintVectorAS: {
    maxWidth: 170,
    width: '100%',
  },
  splashPrintLogoMed: {
    marginTop: 9,
    width: 135,
  },
  splashPrintLogoSmall: {
    marginTop: 11,
    width: 100,
  },
  splashText: {
    marginTop: 24,
  },
  stepperButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tab: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    color: theme.palette.text.primary,
    stroke: theme.palette.text.primary,
    strokeWidth: 0,
  },
  tableCell: {
    '@media print': {
      fontSize: 12,
    },
  },
  tablePaper: {
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    '@media print': {
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
  },
  tabs: {
    '@media print': {
      display: 'none',
    },
  },
  tabInactive: {
    cursor: 'default',
    color: '#CCC',
    [theme.breakpoints.down('md')]: {
      maxWidth: '75px',
    },
  },
  tableIcon: {
    marginBottom: -5,
  },
  tableIconButton: {
    padding: 4,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  textColor: {
    color: theme.palette.text.primary,
  },
  textDisplayGroup: {
    marginRight: 16,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
    },
  },
  textLink: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  titleFilter: {
    marginTop: '-3px',
    marginLeft: '20px',
  },
  tileMarginFix: {
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
    },
  },
  tileText: {
    marginBottom: 5,
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      marginTop: 2,
      fontSize: 14,
    },
  },
  tileTable: {
    '@media only screen and (max-width: 800px)': {
      overflowX: 'scroll',
      paddingBottom: 8,
    },
    [theme.breakpoints.down('md')]: {
      margin: -8,
      width: 'calc(100% + 16px)',
    },
    width: 'calc(100% + 32px)',
    margin: -16,
    '@media print': {
      margin: -8,
      width: 'calc(100% + 16px)',
      paddingTop: 0,
    },
  },
  tileTitle: {
    padding: '4px 0px 4px 0px',
    fontSize: '15px',
    '@media print': {
      padding: '2px 0px 2px 0px',
      fontSize: 14,
    },
  },
  tableDataLabel: {
    fontSize: 12,
  },
  tableWrapper: {
    overflowX: 'auto',
    width: '100%',
  },
  tabsWrapper: {
    position: 'fixed',
    flexGrow: 1,
    width: 'calc(100% - 205px)',
    margin: '-16px',
    zIndex: 50,
    border: 'none',
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% + 10px)',
    },
    '@media print': {
      display: 'none',
    },
  },
  testSelect: {
    marginTop: 20,
    marginBottom: 10,
    '@media print': { marginTop: 4, marginBottom: -2 },
  },
  testSelectAlignment: {
    marginTop: 10,
    '@media print': { marginTop: 4, marginBottom: -2 },
  },
  textDecoNone: {
    textDecoration: 'none',
  },
  textDisplay: {
    marginRight: 4,
    marginBottom: 4,
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    width: 110,
    '@media print': {
      width: 80,
      marginBottom: 2,
    },
    [theme.breakpoints.down('md')]: {
      width: 80,
    },
    [theme.breakpoints.down('sm')]: {
      width: 78,
    },
  },
  textDisplayWide: {
    marginRight: 20,
    marginBottom: 4,
  },
  textDisplayBody: {
    fontSize: 14,
  },
  textDisplayLabel: {
    fontSize: 13,
  },
  textDisplayLabelLarge: {
    fontSize: 15,
    '@media print': {
      fontSize: 13,
    },
  },
  textDisplayBodySpec: {
    '@media print': {
      fontSize: 12,
      lineHeight: 1,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  textDisplayLabelSpec: {
    '@media print': {
      fontSize: 11,
      lineHeight: 1,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  textField: {
    width: 160,
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
  },
  textFieldCompact: {
    //Uses placeholder instead of label
    width: 160,
    marginRight: theme.spacing(1.5),
    marginTop: -10,
  },
  textFieldLarge: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '100%',
  },
  textIcon: {
    verticalAlign: 'super',
  },
  th: {
    padding: '12px 0px',
  },
  tilePaper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 8,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.tile.primary,
    '@media print': {
      padding: '8px',
      boxShadow: 'none',
      border: '1px solid #ddd',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
    },
  },
  titleContainer: {
    height: '32px',
  },
  titleGrid: {
    margin: '10px 0px 0px 0px',
    padding: '0px 5px 0px 30px',
    zIndex: '100',
  },
  titleWrapper: {
    marginTop: 10,
    paddingTop: 8,
    '@media print': {
      marginTop: 8,
      paddingTop: 0,
    },
  },
  toggleButtons: {
    marginTop: 10,
  },
  toolBar: {
    paddingLeft: 20,
    paddingRight: 16,
    minHeight: 52,
    [theme.breakpoints.up('md')]: {
      minHeight: 64,
    },
  },
  totalsContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: 32,
    },
    marginTop: 8,
  },
  transferList: {
    margin: 'auto',
    marginLeft: theme.spacing(-1),
  },
  transferListPaper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  transferListButton: {
    margin: theme.spacing(0.5, 0),
  },
  transferListButtons: {
    marginTop: 20,
  },
  troubleshoot: {
    margin: theme.spacing(1, 0),
  },
  uploadIcon: {
    color: theme.palette.text.primary,
    margin: '3px 10px 0px 0px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
  },
  uploadProgress: {
    backgroundColor: '#ddd',
    margin: 0,
  },
  uploadProgressBar: {
    width: '100%',
    height: 4,
    backgroundColor: '#1c75bc',
  },
  uploadProgressCheck: {
    margin: '3px 5px 0px 0px',
    //color: theme.palette.secondary.main,
  },
  uploadProgressLoad: {
    margin: '4px 8px 0px 1px',
    //color: theme.palette.secondary.main,
  },
  uploadProgressWrapper: {
    maxWidth: 800,
    marginTop: 10,
  },
  userPanel: {
    textAlign: 'left',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  videoContainer: {
    padding: '56.25% 0 0 0',
    position: 'relative',
    marginTop: 18,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.border.color,
  },
  widgetMargin: {
    marginBottom: 4,
    '@media print': {
      marginBottom: 0,
    },
  },
  widgetPrintMargin: {
    '@media print': {
      marginTop: 4,
    },
  },
  widgetSpacing: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 4,
    },
    paddingBottom: 4,
  },
  yellowBG: {
    backgroundColor: theme.palette.yellow.color,
  },
  yellowBGLight: {
    backgroundColor: theme.palette.yellow.light,
  },
  yellowBorder: {
    borderColor: theme.palette.yellow.color,
  },
  yellowText: {
    color: theme.palette.yellow.color,
  },
});
